export const PRIMARY_COLOR = "#013333";
export const PRIMARY_COLOR_TRANS = "#0E4D45b5";
export const SECOND_COLOR = "#0E4D45";

export const ICON_GREEN_COLOR = "#65ffab";
export const WHITE_COLOR = "#ffffff";

export const BLACK_COLOR = "#000000";
export const BLACK_GRAY_COLOR = "#404040";

//Ligth Theme
export const BACKGROUND_GREY = "#fafafa";
export const BACKGROUND_SECOND_GREY = "#eeeeee";
export const GREY_TRANS = "#40404053";

//Border
export const BORDER_GREY = "#bebebe";

// Cluster color
export const EVENT_CLUSTER_COLOR = "#810955";
export const OBJECT_CLUSTER_COLOR = "#ff5f7e";
export const SENSOR_CLUSTER_COLOR = "#e00476";
export const CSV_CLUSTER_COLOR = "#d62ad0";
export const COMPONENT_CLUSTER_COLOR = "#251351";

export const RED_COLOR = "#ff0000";
export const DELETE_COLOR = "#D11010";
export const HOVER_DELETE_COLOR = "#EE1B1B";

export const VISIBILITY_OFF_ICON = "#969292";
export const SET_VISIBILITY_ICON = "#ff7c36";

export const WHITE_SECOND_COLOR = "#f9f9f9";
export const WHITE_BACKGROUND = "#f9f9f9";

export const SPACE_COLOR = "#131313";

export const INFORMATION_COLOR = "#3f51b5";
export const SELECTION_COLOR = "#1084f0";

import { CustomContainer, BannerDescription, BannerTitle, LogoDecimetrix, NeuronImage } from "./ForgotPasswordElemts";
import LogoSocrates from "../../assets/decimetrix_socrates.png";

export default function BannerDecimetrix() {
    const neuronUrl = "https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png";
    return (
        <CustomContainer>
            <NeuronImage src={neuronUrl} />
            <LogoDecimetrix className="form-image" src={LogoSocrates} alt="logo" />
            <BannerTitle>
                DECIMETRIX® SOCRATES <br /> ARTIFICIAL INTELLIGENCE ENTERPRISE ASSISTANT
            </BannerTitle>
            <BannerDescription>
                Artificial intelligence solution capable of analyzing, translating and answering questions about <br /> the information contained in reports and
                enterprise databases.
            </BannerDescription>
        </CustomContainer>
    );
}

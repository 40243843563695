import { config } from "../../../config";

const URL_BACKEND_MG = config.URL_BACKEND_MG;

export const loginUser = async (body: any) => {
    try {
        const res = await fetch(`${URL_BACKEND_MG}api/users/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify(body)
        });
        const response = await res.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const userVerification = async (email: string) => {
    try {
        const res = await fetch(`${URL_BACKEND_MG}api/users/list?email=${email}`, {
            method: "GET"
        });
        const response = await res.json();

        return response;
    } catch (error) {
        console.error(error);
    }
};

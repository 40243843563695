import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Decimetrix® Socrates</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}></ul>
                    </nav>
                    <h4 className={styles.headerRightText}>Decimetrix® AI Chat</h4>
                </div>
            </header>

            <Outlet />

            <footer className={styles.footerText}>
                <ul className={styles.footerNavList}>
                    <li className={styles.listStyle}> © All Rights Reserved.</li>
                    <li className={styles.listStyle}>
                        {" "}
                        <a href="https://greendragon.decimetrix.com/terms-and-conditions-socrates" title="Terms and Conditions Link">
                            Terms and Conditions{" "}
                        </a>
                    </li>
                    <li className={styles.listStyle}> & </li>
                    <li className={styles.listStyle}>
                        {" "}
                        <a href="https://greendragon.decimetrix.com/privacy-policy-socrates" title="Privacy Policy Link">
                            Privacy Policy.{" "}
                        </a>
                    </li>
                </ul>
            </footer>
        </div>
    );
};

export default Layout;

import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR } from "../../utils/const";

export const CustomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    position: relative;

    @media (max-width: 768px) {
        margin-top: 50px;
    }
`;

export const NeuronImage = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.7;
    width: 40%;
    transform: translate(-50%, -30%);
    z-index: 1;
`;

export const LogoDecimetrix = styled.img`
    width: 60%;
    z-index: 10;
`;

export const BannerTitle = styled.p`
    font-size: 2.5rem;
    font-weight: bold;
    color: ${PRIMARY_COLOR};
    margin: 4rem 0;
    text-align: center;
    z-index: 10;
`;

export const BannerDescription = styled.p`
    font-size: 1.8rem;
    text-align: center;
    z-index: 10;
    color: ${BLACK_COLOR};
`;

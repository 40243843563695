import styled from "styled-components";
import { MdArrowForward, MdArrowRight } from "react-icons/md";
import { BLACK_COLOR, PRIMARY_COLOR, SECOND_COLOR, WHITE_COLOR } from "../../utils/const";

interface LoginContainerProps {
    out?: boolean;
}

export const LoginContainer = styled.div<LoginContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    margin-top: ${props => (props.out ? "80px" : "80px")};

    .small {
        height: 90%;
        padding: 0 2rem;
        position: relative;
    }
    p {
        margin-bottom: 20px;
    }
`;

export const LogoDecimetrix = styled.img`
    width: 75%;
    z-index: 10;
`;

export const Description = styled.p`
    font-size: 2.5rem;
    font-weight: bold;
    color: ${PRIMARY_COLOR};
    margin: 4rem 0;
    text-align: center;
    z-index: 10;

    .small {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
        color: red;
    }
`;

export const LoginForm = styled.form`
    min-width: 350px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 2rem;
    border: 1px solid rgba(198, 197, 197, 0.609);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    position: relative;
    color: ${BLACK_COLOR};
    background-color: ${WHITE_COLOR};

    .form-image {
        width: 20%;
        position: absolute;
        top: -3.5rem;
        left: 35%;
    }
`;

export const LoginTitle = styled.p`
    font-size: 2rem;
    color: ${SECOND_COLOR};
    font-weight: bold;
    margin-bottom: 2rem;
`;

export const PStyled = styled.p`
    font-size: 1.4rem;
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    padding: 0;
    margin: 10px 5px;

    .black {
        color: black;
        padding: 0;
    }

    &:hover {
        color: ${SECOND_COLOR};
    }
`;

export const PStyle = styled.p`
    font-size: 1.4rem;
    color: ${PRIMARY_COLOR};
    cursor: default;
    padding: 0;
    margin: 10px 5px;
`;

export const LoginInput = styled.input`
    width: 100%;
    min-height: 40px;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid #18213a;
`;

export const Checkboxarea = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    color: ${PRIMARY_COLOR};
    margin-top: 2rem;
    font-size: 1.4rem;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 2rem;
`;

export const ArrowRight = styled(MdArrowRight)`
    margin-right: 8px;
    font-size: 2rem;
`;

export const ImgWrap = styled.div`
    max-width: 500px;
    height: 100%;
    display: grid;
    height: 500px;
    justify-content: center;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const SocialIconLink = styled.a`
    color: #222729;
    font-size: 2.4rem;
`;

export const Divider = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LineDivider = styled.hr`
    height: 2px;
    border: none;
    background-color: rgb(225, 225, 225);
    flex: 1 1 0%;
`;

export const TextDivider = styled.div`
    background-color: rgb(255, 255, 255);
    color: rgb(97, 97, 97);
    font-size: 1.4rem;
    line-height: 18px;
    padding: 0px 10px;
`;
export const RestartLink = styled.button`
    background-color: rgb(255, 255, 255);
    color: rgb(97, 97, 97);
    font-size: 1.4rem;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #222729;
    }
`;

export const LoginButton = styled.button`
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    font-size: 1.6rem;
    margin-top: 10px;
    height: 50px;
    padding: 10px;
    border: none;
    border-radius: 15px;
    width: 100%;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: ${SECOND_COLOR};
    }
`;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../components/Forms/Input";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import { LoginContainer, LoginForm, ArrowForward, ArrowRight, LoginButton } from "./LoginElements";
import BannerDecimetrix from "./BannerDecimetrix";
import { MessageError, MessageSuccess } from "../../components/Forms/FormStyles";

// Services
import { loginUser } from "../../services/users/login";

const Login = () => {
    const rememberedUser = JSON.parse(localStorage.getItem("rememberUser") || "{}");
    const [password, setPassword] = useState<{ field: string; valid: string }>({
        field: rememberedUser ? rememberedUser.password : "",
        valid: ""
    });
    const [email, setEmail] = useState<{ field: string; valid: string }>({
        field: rememberedUser ? rememberedUser.email : "",
        valid: ""
    });
    const [FormValidated, setFormValidated] = useState<boolean | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const expressions = {
        password: /^.{8,20}$/,
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    };

    const navigate = useNavigate();
    const [hover, setHover] = useState(false);

    const OnSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const body = {
            email: email.field,
            password: password.field
        };
        const payload = await loginUser(body);
        if (payload.user.email !== email.field) {
            setFormValidated(false);
            return;
        } else {
            if (payload.statusCode === 403) {
                setFormValidated(false);
                return;
            } else {
                if (payload.statusCode === 401 || payload.statusCode === 404) {
                    setFormValidated(false);
                } else {
                    // set login status in local storage
                    localStorage.setItem("loginStatus", "true");
                    const role = payload.user.userType;

                    // set user type in local storage
                    localStorage.setItem("role", role);
                    localStorage.setItem("token", payload.user.token);

                    if (role === "Administrador de decimetrix") {
                        //set user id in local storage
                        localStorage.setItem("adminDecimetrixId", payload.user._id);
                        setFormValidated(true);
                        navigate("/IA");
                    } else {
                        setFormValidated(false);
                    }
                }
            }
        }
    };
    const onHover = () => {
        setHover(!hover);
    };

    const closeDialog = () => {
        setOpenDialog(false);
    };

    return (
        <LoginContainer>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <BannerDecimetrix />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <LoginContainer className="small">
                            <LoginForm onSubmit={OnSubmit}>
                                <InputComponent
                                    state={email}
                                    changeState={setEmail}
                                    type="email"
                                    name="email"
                                    label="E-mail address"
                                    placeholder="your.email@gmail.com"
                                    legendError="The email can only contain letters, numbers, periods, hyphens and underscore."
                                    regularExpression={expressions.email}
                                ></InputComponent>
                                <InputComponent
                                    state={password}
                                    changeState={setPassword}
                                    type="password"
                                    name="password"
                                    label="Password"
                                    placeholder="********"
                                    legendError="the password must be at least 8 characters."
                                    regularExpression={expressions.password}
                                ></InputComponent>
                                <LoginButton type="submit" onMouseEnter={onHover} onMouseLeave={onHover}>
                                    Log In {hover ? <ArrowForward /> : <ArrowRight />}
                                </LoginButton>
                                {loading ? (
                                    <Box
                                        sx={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : null}
                                <div>
                                    {FormValidated === false && <MessageError> {message} </MessageError>}
                                    {FormValidated === true && <MessageSuccess> Form sent successfully! </MessageSuccess>}
                                </div>
                            </LoginForm>
                        </LoginContainer>
                    </Grid>
                </Grid>
            </Container>
        </LoginContainer>
    );
};

export default Login;

import styled, { css } from "styled-components";
import { PRIMARY_COLOR } from "../../utils/const";

const colors = {
    border: "#15bbfd",
    error: "#f67070",
    success: "#54c25d"
};
interface WrapperInputProps {
    width?: string;
}

export const WrapperInput = styled.div<WrapperInputProps>`
    width: ${props => (props.width ? props.width : "100%")};
    position: relative;
    z-index: 90;
    .input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
    }
    .login-visibility {
        position: absolute;
        right: 0px;
        margin: 0 10px;
        color: ${PRIMARY_COLOR};
    }
    .login-visibility:hover {
        cursor: pointer;
    }
`;

interface InputProps {
    valid?: string;
}

export const Input = styled.input<InputProps>`
    width: 100%;
    background: #f1f1f1;
    border-radius: 10px;
    height: 40px;
    line-height: 40px;
    padding: 10px 40px 10px 20px;
    transition: 0.3s ease all;
    border: none;
    /* border-bottom: 1px solid #2e2e2e; */
    display: flex;
    font-size: 1.5rem;

    &.little {
        transform: scale(0.5);
        margin-top: 0;
    }

    &:focus {
        border: 3px solid ${colors.border};
        outline: none;
        box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
    }

    ${props =>
        props.valid === "true" &&
        css`
            border: 3px solid transparent;
        `}

    ${props =>
        props.valid === "false" &&
        css`
            border: 3px solid ${colors.error} !important;
        `}
`;

interface LabelProps {
    valid?: string;
}

export const Label = styled.label<LabelProps>`
    position: relative;
    display: block;
    font-weight: 600;
    cursor: pointer;
    margin: 10px 0;
    color: none;
    font-size: 1.6rem;
    /* border: solid 2px blue; */
    z-index: 1;

    ${props =>
        props.valid === "false" &&
        css`
            color: ${colors.error};
        `}
`;
interface LegendErrorProps {
    valid?: string;
}

export const LegendError = styled.p<LegendErrorProps>`
    font-size: 1.4rem;
    margin-bottom: 0;
    color: ${colors.error};
    display: none;

    ${props =>
        props.valid === "true" &&
        css`
            display: none;
        `}

    ${props =>
        props.valid === "false" &&
        css`
            display: block;
        `}
`;

export const MessageError = styled.div`
    height: 30px;
    line-height: 45px;
    color: #f66060;
    padding: 0px 15px;
    border-radius: 3px;
    margin: 10px auto;
    font-size: 1.6rem;
`;

export const MessageSuccess = styled.p`
    height: 30px;
    line-height: 45px;
    padding: 0px 15px;
    border-radius: 3px;
    margin: 10px auto;
    color: ${colors.success};
`;
